/**
 * User Action Types
 */

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const GET_INDUSTRIES_REQUEST = 'GET_INDUSTRIES_REQUEST';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAIL = 'GET_INDUSTRIES_FAIL';

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const GET_PROFICIENCIES_REQUEST = "GET_PROFICIENCIES_REQUEST";
export const GET_PROFICIENCIES_SUCCESS = "GET_PROFICIENCIES_SUCCESS";
export const GET_PROFICIENCIES_FAIL = "GET_PROFICIENCIES_FAIL";

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const EXPORT_COMPANY_REQUEST = "EXPORT_COMPANY_REQUEST";
export const EXPORT_COMPANY_SUCCESS = "EXPORT_COMPANY_SUCCESS";
export const EXPORT_COMPANY_FAIL = "EXPORT_COMPANY_FAIL";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const UPDATE_ADMIN_COMPANY_REQUEST = "UPDATE_ADMIN_COMPANY_REQUEST";
export const UPDATE_ADMIN_COMPANY_SUCCESS = "UPDATE_ADMIN_COMPANY_SUCCESS";
export const UPDATE_ADMIN_COMPANY_FAIL = "UPDATE_ADMIN_COMPANY_FAIL";

export const SET_UPDATE_COMPANY = "SET_UPDATE_COMPANY"

export const GET_COMPANY_DETAIL_REQUEST = "GET_COMPANY_DETAIL_REQUEST";
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";


export interface AddFirmState {
  loading: boolean;
  getCountry: any;
  getIndustries: any;
  getCompany: any;
  getProficiencies: any;
  addCompany: any;
  getExportCompany: any;
  deleteCompany: any;
  updateAdminCompany: any;
  getCompanyDetail: any;
  getDashboardData: any;
  error: any;
}
