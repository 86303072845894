import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CloudServices from '../../admin/crawl-status/CloudServices';
import AddFirm from '../../admin/home/AddFirm';
import CrawlStatus from '../../admin/home/CrawlStatus';
import ExportCompanyData from '../../admin/home/ExportCompanyData';
import FirmAdvertisement from '../../admin/home/FirmAdvertisement';
import Home from '../../admin/home/Home';
import Subscription from '../../admin/home/Subscription';
import TractionReport from '../../admin/home/TractionReport';
import LinkedInLogin from '../../admin/login/LinkedInLogin';
import LoginCallback from '../../admin/login/LoginCallback';
import PrivacyPolicay from '../../admin/login/PrivacyPolicay';
import ResetPwd from '../../admin/login/ResetPwd';
import SignIn from '../../admin/login/SignIn';
import SignUp from '../../admin/login/SignUp';
import Terms from '../../admin/login/Terms';
import Claims from '../../admin/traction-report/Claims';
import Footer from './Footer';
import Header from './Header';
import SideMenu from './SideMenu';
import AddAdvertisement from '../../admin/home/AddAdvertisement';
import StorageService from '../../services/StorageService';
import EditFirm from '../../admin/home/EditFirm';
import ContactInfo from '../../admin/contact-info';
import AddContactInfo from '../../admin/contact-info/AddContactInfo';
import EditContactInfo from './../../admin/contact-info/EditContactInfo';
import Dashboard from '../../admin/home/Dashboard';

const DefaultLayout = () => {
  require('../../admin/assets/scss/main.scss');
  return (
    <>
      {!StorageService.getToken() ? (
        <div>
          <div
            className="vertical-layout vertical-menu-modern 2-columns navbar-sticky fixed-footer"
            data-open="click"
            data-menu="vertical-menu-modern"
            data-col="2-columns"
          >
            <ToastContainer position="bottom-right" />
            <Switch>
              <Route exact path="/admin">
                <Route index component={SignIn} />
                <Route exact path="/admin/signup" component={SignUp} />
                <Route exact path="/admin/terms" component={Terms} />
                <Route exact path="/admin/privacy-policy" component={PrivacyPolicay} />
                <Route exact path="/admin/reset-pwd" component={ResetPwd} />
                <Route exact path="/admin/login/callback" component={LoginCallback} />
              </Route>
            </Switch>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="vertical-layout vertical-menu-modern 2-columns  navbar-sticky fixed-footer"
            data-open="click"
            data-menu="vertical-menu-modern"
            data-col="2-columns"
          >
            <SideMenu />
            <Header />
            <ToastContainer position="bottom-right" />
            <Switch>
              <Route exact path="/admin" component={Dashboard} />
              <Route exact path="/admin/firms" component={Home} />
              <Route exact path="/admin/login" component={LinkedInLogin} />

              <Route exact path="/admin/traction-report" component={TractionReport} />
              <Route exact path="/admin/firmAdvertisement" component={FirmAdvertisement} />
              <Route exact path="/admin/firmAdvertisement/addAdvertisements" component={AddAdvertisement} />
              <Route exact path="/admin/newSubscription" component={Subscription} />
              <Route exact path="/admin/exportCompanyData" component={ExportCompanyData} />
              <Route exact path="/admin/addFirm" component={AddFirm} />
              <Route exact path="/admin/firms/editFirm" component={EditFirm} />
              <Route exact path="/admin/crawlStatus" component={CrawlStatus} />
              <Route exact path="/admin/crawlStatus/cloudServices" component={CloudServices} />
              <Route exact path="/admin/traction-report/detail" component={Claims} />
              <Route exact path="/admin/contact-info" component={ContactInfo} />
              <Route exact path="/admin/contact-info/add-contact" component={AddContactInfo} />
              <Route exact path="/admin/contact-info/edit-contact" component={EditContactInfo} />
            </Switch>
            <div className="fixed-bottom footer-fix footer-light">
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultLayout;
