import React from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import SiteLayout from "./layout/site/index";
import AdminLayout from "./layout/admin/index";

const Routes = () => {
    return ( <> <Switch>
        <Route path="/admin" component={AdminLayout}/>
        <Route path="/" component={SiteLayout}/>
    </Switch> </>);
};

export default connect()(withRouter(Routes));